.features {
	position: relative;

	&::before {
		content: '';
		width: 100%;
		height: 400px;
		position: absolute;
		left: 0;
		bottom: 0;
		background: color(bg, 2);
		background: linear-gradient(to bottom, color(bg, 2), rgba(color(bg, 2), 0));
	}
}

.features-wrap {
    display: flex;
    flex-wrap: wrap;
	/* align-items: flex-start; // disables equal height boxes */
    justify-content: center;
    margin-right: -16px;
    margin-left: -16px;

    &:first-child {
        margin-top: -16px;
    }

    &:last-child {
        margin-bottom: -16px;
    }
}

.feature {
	position: relative;
    padding: 16px;
    width: 388px;
    max-width: 388px;
    flex-grow: 1;
}

.feature-inner {
    position: relative;
    height: 100%;
    background: color(bg, 1);
    padding: 48px 24px 72px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include shadow;
        mix-blend-mode: multiply;
    }
}

.feature-icon {
	position: relative;
    margin: 0 auto;
	width: 88px;
	height: 88px;
	border-radius: 50%;

	&::before {
		content: '';
		display: block;
		background: color(bg, 1);
		width: 64px;
		height: 64px;
		position: absolute;
		top: 12px;
		left: 12px;
		border-radius: 50%;
		box-shadow: 12px 24px 32px rgba(color(typography,1), .12);
	}

	svg {
		position: relative; /* To display the svg icon above the background color */
	}
}

@include media( '>medium' ) {

    .features {

		.section-paragraph {
			padding-left: 72px;
			padding-right: 72px;
		}
    }

	.features-wrap {
		padding-top: 24px;
	    margin-right: -24px;
	    margin-left: -24px;

	    &:first-child {
	        margin-top: -24px;
	    }

	    &:last-child {
	        margin-bottom: -24px;
	    }
	}

	.feature {
	    padding: 24px;
	}
}

@include media( '>large' ) {

	.features {

		.section-inner {
			padding-bottom: 168px; /* 88+80px second column offset */
		}
	}

	.feature {

		&:nth-child(even) {
			top: 80px;
		}
	}
}
