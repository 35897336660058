// --------------------------------------------
// Colors -------------------------------------
// Usage example: color(primary, main)
// --------------------------------------------
$color: (
	typography: (
		1: #11103E,
		2: #565678,
		3: #ABABC9,
		1i: #FFF,
		2i: rgba( #FFF, .4 )
	),
	bg: (
		1: #FFFFFF,
		2: #EEF1FA,
		3: #D2DAF0
	),
	primary: (
		1: #4950F6,
		2: #8D92FA,
		3: #261FB6,
		4: #E0E1FE
 	),
 	secondary: (
		1: #FF6381,
		2: #FF97AA,
		3: #FF3058,
		4: #FFD2DA
 	)
);

// --------------------------------------------
// Typography ---------------------------------
// --------------------------------------------
$font__family: (
	base: '"Heebo", sans-serif', // font-family(base)
	heading: 'Fira Sans, serif', // font-family(heading)
	code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace', // font-family(code)
	pre: '"Courier 10 Pitch", Courier, monospace' // font-family(pre)
);

$font__sizes: (
	alpha:   ( 46px, 56px, 0px ), // font-size, line-height, kerning (use '0' if don't want to output any kerning)
	beta:    ( 40px, 50px, 0px ),
	gamma:   ( 32px, 42px, 0px ),
	delta:   ( 22px, 32px, -0.1px ),
	epsilon: ( 20px, 30px, -0.1px ),
	zeta:    ( 18px, 27px, -0.1px ),
	eta:     ( 16px, 24px, -0.1px ),
	theta:   ( 14px, 20px, 0px )
);

$font__scale: (
	desktop: (                             // i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
		1: map-get($font__sizes, alpha),   // H1
		2: map-get($font__sizes, beta),    // H2
		3: map-get($font__sizes, gamma),   // H3
		4: map-get($font__sizes, delta),   // H4, H5, H6
		5: map-get($font__sizes, epsilon), // Body
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller
		8: map-get($font__sizes, theta)    // Footer area
	),
	mobile: (                              // i.e. $breakpoint__xs + $breakpoint__s (up to 600)
		1: map-get($font__sizes, beta),    // H1
		2: map-get($font__sizes, gamma),   // H2
		3: map-get($font__sizes, delta),   // H3
		4: map-get($font__sizes, epsilon), // H4, H5, H6
		5: map-get($font__sizes, zeta),    // Body
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller
		8: map-get($font__sizes, theta)    // Footer area
	)
);

$font__weight: (
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semi-bold)
	bold: 700		// font__weight(bold)
);

// --------------------------------------------
// Structure ----------------------------------
// --------------------------------------------
$content__padding: (
	mobile: 16px,
	desktop:  24px
);
$container__width: 1080px;
$container__width-sm: 800px;
