.site-header {
    position: relative;
    padding: 24px 0;
}

.header-shape {
	position: absolute;
}

.header-shape-1 {
	display: none;
	top: 0;
}

.header-shape-2 {
	top: -40px;
	right: -53px;
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-links {
    display: inline-flex;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(7, mobile, true, true, true);
        @if ( get-font-size(7, desktop) != get-font-size(7, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(7, desktop, true, true, true);
            }
        }
        @include font-weight(bold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@include media( '>medium' ) {

	.header-shape-1 {
		display: block;
		left: 687px;
	}

	.header-shape-2 {
		top: -92px;
		left: 440px;
		right: auto;

		svg {
			width: 220px;
			height: 220px;
		}
	}
}

@include media( '>large' ) {

	.header-shape-1 {
		left: auto;
		right: 0;
	}

	.header-shape-2 {
		left: auto;
		right: 38%;
	}
}
