.site-footer {
	position: relative;
    @include font-size(8, mobile, true, true, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, true, true);
        }
    }
    color: color(typography, 3);
	background-color: color(typography, 1);

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 30%;
		width: 298px;
		height: 93px;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjk4IiBoZWlnaHQ9IjkzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPiAgPGRlZnM+ICAgIDxsaW5lYXJHcmFkaWVudCB4MT0iMjguOTQlIiB5MT0iLTMwLjY4MyUiIHgyPSI2Ny40NzMlIiB5Mj0iMTM1LjAyMiUiIGlkPSJhIj4gICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNTY1Njc4IiBvZmZzZXQ9IjAlIi8+ICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iIzU2NTY3OCIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjEwMCUiLz4gICAgPC9saW5lYXJHcmFkaWVudD4gIDwvZGVmcz4gIDxwYXRoIGQ9Ik00MDMuMjQ0IDY5MGMyNC4yMDktNTQuNzc2IDc5LjAxOS05MyAxNDIuNzU2LTkzIDUzLjI1NSAwIDEyMy40NTggNTAuNjc0IDE5Ny4yMTUgOTNoLTMzOS45N3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MDMgLTU5NykiIGZpbGw9InVybCgjYSkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbC1vcGFjaXR5PSIuMjQiLz48L3N2Zz4=);
		background-repeat: no-repeat;
	}

    a {
        @include anchor-aspect(footer);
    }
}

.site-footer-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    flex-wrap: wrap;
    padding-top: 48px;
    padding-bottom: 48px;

	&.has-top-divider {

		&::before {
			background: color(typography, 2);
			background: linear-gradient(to right, rgba(color(typography, 2), .1) 0, rgba(color(typography, 2), .6) 50%, rgba(color(typography, 2), .1) 100%);
		}
	}
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
    flex: none;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.footer-brand,
.footer-links,
.footer-social-links {
    margin-bottom: 24px;
}

.footer-links,
.footer-social-links {

    li {

        + li {
            margin-left: 16px;
        }
    }
}

.footer-social-links {

    li {
        display: inline-flex;

        a {
            padding: 8px;
        }
    }
}

@include media( '>medium' ) {

    .site-footer-inner {
        justify-content: space-between;
    }

    .footer-brand,
    .footer-links,
    .footer-social-links,
    .footer-copyright {
        flex: 50%;
    }

    .footer-brand,
    .footer-copyright {
        justify-content: flex-start;
    }

    .footer-links,
    .footer-social-links {
        justify-content: flex-end;
    }

    .footer-links {
        order: 1;
        margin-bottom: 0;
    }
}
