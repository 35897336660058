.hero {
	text-align: center;
    padding-top: 40px;
}

.hero-paragraph {
	@include font-size(4, mobile, true, true, true);
	@if ( get-font-size(4, desktop) != get-font-size(4, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(4, desktop, true, true, true);
		}
	}
    margin-bottom: 40px;
}

.hero-form {
	max-width: 440px;
	margin: 0 auto;
}

.hero-illustration {
	position: relative;
    margin-top: 48px;
}

.hero-shape {
	display: none;
    position: absolute;
}

.hero-main-shape {

	svg {
		width: 140%;
		height: auto;
	}
}

@include media( '>medium' ) {

    .hero {
		text-align: left;
        padding-top: 160px;
    }

	.hero-inner {
		/* Split hero in two half */
		display: flex;
	}

	.hero-copy {
		padding-right: 48px;
		min-width: 512px;
	}

	.hero-form {
		margin: 0;
	}

	.hero-illustration {
		margin-top: -143px;
		padding-bottom: 0;
	}

	.hero-shape {
		display: block;
	}

	.hero-shape-1 {
		left: -32px;
		top: 542px;
	}

	.hero-shape-2 {
		left: -733px;
		top: 538px;
	}

	.hero-main-shape {

		svg {
			width: auto;
			height: 647px;
		}
	}
}

@include media( '>large' ) {

    .hero-copy {
        padding-right: 92px;
        min-width: 620px;
    }
}
