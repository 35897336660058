.button {
    display: inline-flex;
    @include font-size(8, mobile, true, false, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, false, true);
        }
    }
    @include font-weight(bold);
	text-transform: uppercase;
    line-height: 16px;
    text-decoration: none !important;
    background-color: color(bg, 1);
    color: color(primary, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;
}

.button-primary {
    color: color(typography, 1i) !important;
    transition: background .15s ease;
}

.button-primary {
    background: color(primary, 1);

    &:hover {
        background: darken(color(primary, 1), 2%);
    }
}

.button-block {
    display: flex;
}
